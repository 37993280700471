<script>
import Layout from "@/router/layouts/franchise";
import { VclList } from "vue-content-loading";
import { api } from "@/state/services";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
      Inventory: "Estoque",
      Products: "Produtos",
    },
    es: {
      Inventory: "Estoque",
      Products: "Produtos",
    },
  },
  components: {
    Layout,
    VclList,
    Stat
  },
  data() {
    return {
      products: null,
      statData: null,
    };
  },
  methods: {
    getInventory() {
      api
        .get("franchise/inventory")
        .then((response) => {
          if (response.data.status == "success") {
            this.products = response.data.list;

            this.statData = [
              {
                icon: "bx bx-dollar",
                title: "Total",
                value: this.$options.filters.currency(response.data.value),
              },
            ];
          } else {
            this.products = [];
          }
        })
        .catch((error) => {
          if (error) {
            this.products = [];
          }
        });
    },
  },
  mounted() {
    this.getInventory();
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">Estoque</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Produtos</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div v-for="(stat, index) of statData" :key="index" class="col-md-4">
        <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <vcl-list v-if="!products" class="col-lg-6"></vcl-list>
          <template v-else-if="products.length == 0">
            Nenhum registro encontrado.
          </template>
          <table v-else class="table table-nowrap table-hover">
            <thead class="thead-light">
              <tr>
                <th style="width: 1%"></th>
                <th class="text-center" style="width: 1%">Total</th>
                <th class="text-center" style="width: 1%">Disponível</th>
                <th class="text-center" style="width: 1%">Reservado</th>
                <th>Nome</th>
                <th class="text-right">Preço</th>
                <th class=" text-right">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pro, index) in products" :key="index">
                <td class="py-1">
                  <div class="pt-1">
                    <span class="rounded-circle" :style="'float:left; background:' +
                      pro.color +
                      '; width:13px; height:13px;'
                      "></span>
                  </div>
                </td>
                <td class="text-center py-1">{{ pro.total }}</td>
                <td class="text-center py-1">{{ pro.available }}</td>
                <td class="text-center py-1">{{ pro.reserved }}</td>
                <td class="py-1">{{ pro.name }}</td>
                <td class="text-right">{{ pro.price | currency }}</td>
                <td class="text-right">{{ pro.value | currency }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </Layout>
</template>
